import { useTheme } from '@material-ui/core/styles'

import placeholderImgWhite from '../assets/images/placeholder-white.jpg'
import placeholderImgBlack from '../assets/images/placeholder-black.jpg'

import gears5 from '../assets/images/news/gears5.jpg'
import gearsEsports from '../assets/images/news/gearsEsports.jpg'
import gearsOfWar from '../assets/images/news/gearsOfWar.jpg'
import gearsPop from '../assets/images/news/gearsPop.jpg'
import gearsTactics from '../assets/images/news/gearsTactics.jpg'
import theCoalition from '../assets/images/news/theCoalition.jpg'

const IMAGES = {
    placeholderImg: { white: placeholderImgWhite, black: placeholderImgBlack },
    gears5: { white: gears5, black: gears5 },
    gearsEsports: { white: gearsEsports, black: gearsEsports },
    gearsOfWar: { white: gearsOfWar, black: gearsOfWar },
    gearsPop: { white: gearsPop, black: gearsPop },
    gearsTactics: { white: gearsTactics, black: gearsTactics },
    theCoalition: { white: theCoalition, black: theCoalition }
}

const useThemedImage = (name = 'placeholderImg') => {
    const theme = useTheme()

    if (theme.palette.type === 'light') {
        return IMAGES[name].white
    } else {
        return IMAGES[name].black
    }
}

export default useThemedImage

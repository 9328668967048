import React, { useState, useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import get from 'tcweb-material-components/core/poly/get'
import map from 'tcweb-material-components/core/poly/map'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Collapse, Typography } from '@material-ui/core'
import Link from '../common/link'
import SideBarHero from './sideBarHero'

const useClasses = makeStyles(({ palette, breakpoints, spacing }) => ({
    root: {
        'background-color': palette.common.default,
        width: '20%',
        paddingLeft: '1em',
        paddingTop: '1em',
        position: 'absolute !important',
        zIndex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    listLink: {
        textTransform: 'uppercase',
        '&:hover': {
            'text-decoration':'none'
        },
        '&:focus': {
            'text-decoration':'none'
        }
    },
    subListLink: {
        paddingBottom: `${spacing(2)}px`,
        textTransform: 'uppercase',
        '&:hover': {
            'text-decoration':'none'
        },
        '&:focus': {
            'text-decoration':'none'
        }
    },
    subList: {
        borderLeft: '2px solid',
        paddingTop: `${spacing(.5)}px`,
        paddingBottom: `${spacing(.5)}px`,
        '&:hover': {
            borderLeft: `2px solid ${palette.primary.main}`
        },
    },
    subListActive: {
        backgroundColor: palette.primary.main,
        paddingTop: `${spacing(.5)}px`,
        paddingBottom: `${spacing(.5)}px`,
        width: '65%',
        borderLeft: `2px solid ${palette.primary.main}`,
        marginRight: '10px',
        '&:hover': {
            backgroundColor: palette.primary.contrastText,
            color: palette.primary.main,
            'text-decoration':'none',
        },
    },
    subListActiveLink: {
        width: '100%',
        textTransform: 'uppercase',
        '&:hover': {
            'text-decoration':'none',
            color: `inherit`,
        },
        '&:focus': {
            color: `inherit`,
            'text-decoration':'none',
        }
    },
    subListItem: {
        'text-indent': '0.5em',
        paddingBottom: `${spacing(2)}px`,
    },
    sideBarHeroContainer: {
        width: '50%',
        marginLeft: '20%',
        [breakpoints.down('md')]: {
            margin: '0',
            width: '100%'
        }
    },
    sideBarHeroTitle: {
        textTransform: 'uppercase',
        fontSize: '8em',
        [breakpoints.down('md')]: {
            fontSize: '6em',
            padding: '50px'
        },
        [breakpoints.down('sm')]: {
            fontSize: '3em'
        }
    }
}))

const getCurrentTocItem = (tocData, pathname) => {
    if(get(tocData, 'path') === pathname) return tocData

    const children = get(tocData, 'children', [])
    if(children.length > 0){
        for (let child of children) {
            const result = getCurrentTocItem(child, pathname)
            if(result) return result
        }
    }
    return null
}

const SideBar = (props) => {
    const tocData = get(props, '[0].pageContext.fullTableOfContents')
    const pathname = get(props, "[0].path", '')
    const currentItem = getCurrentTocItem(tocData, pathname)
    const isFrontPage = get(currentItem, 'isFrontPage')
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const classes = useClasses()
    const [activeParent, setActiveParent] = useState('/' + get(props, "[0].path", '').split('/').slice(1,3).join('/').concat('/'))
    const [checked, setChecked] = useState(false)

    if (!tocData || isFrontPage) {
        return (
            <></>
        )
    }

    const handleClick = (parentUri) => {
        if (parentUri === activeParent) {
            setChecked(false)
            return setActiveParent('')
        }
        setChecked(false)
        setActiveParent(parentUri)
    }

    useEffect(() => {
        if (activeParent !== '') {
            setChecked(true)
        } else {
            console.log(activeParent)
        }
    }, [activeParent])

    const navList = (listData) => {

        const listItems = get(listData, 'children', []).map((item) => {
            if (item.path === activeParent) {
                return (
                    <div key={item.path}>
                        <Link className={classes.listLink}><Typography onClick={e => handleClick(item.path)} >{item.title}</Typography></Link>
                        <Collapse in={checked}>
                            <div className={classes.subListItem}>
                                {map((item) => {
                                    if (item.path === pathname) {
                                        return (
                                            <div className={classes.subListActive} key={item.path}>
                                                <Link to={item.path} className={classes.subListActiveLink}><Typography>{item.title}</Typography></Link>
                                            </div>
                                        )
                                    }
                                    return (
                                        <div key={item.path} className={classes.subList}>
                                            <Link to={item.path} className={classes.subListLink}><Typography>{item.title}</Typography></Link>
                                        </div>
                                    )
                                }, get(item, 'children', []))}
                            </div>
                        </Collapse>
                    </div>
                )
            }

            return (
                <div key={item.path}>
                    <Link onClick={e => handleClick(item.path)} className={classes.subListLink}><Typography>{item.title}</Typography></Link>
                </div>
            )
        })
        return (
            <div className={classes.spacing}>
                {listItems}
            </div>
        )
    }

    return (
        <>
            {!isMobile && (
                <div className={classes.root}>
                    <Link to={get(tocData, 'path')} className={classes.subListLink}><Typography>Home</Typography></Link>
                    {navList(tocData)}
                </div>
            )}

            <SideBarHero>
                <div className={classes.sideBarHeroContainer}>
                    <Typography variant='h1' className={classes.sideBarHeroTitle}>{get(currentItem, 'title')}</Typography>
                </div>
            </SideBarHero>
        </>
  )
}

export default SideBar
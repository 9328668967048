import React from 'react'
import SideBar from './sideBar'

const SideNav = (...props) => {
  return (
    <>
      <SideBar {...props} />
    </>
  )
}

export default SideNav
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import get from 'tcweb-material-components/core/poly/get'
import { makeStyles, useTheme } from '@material-ui/styles'
import Box from 'tcweb-material-components/core/box'
import classnames from 'classnames'
import Cookie from 'js-cookie'

import Header from '../header'
import SideNav from '../sidenav'
import Footer from '../footer'
import AgeGate from '../modal/ageGate'
import AuthGate from '../common/authGate'
import { Skeleton } from '@material-ui/lab'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: { paddingBottom: '4em' }
    },
    vetricalGuttersPage: {
        paddingTop: '50px',
        paddingBottom: '50px',
        [theme.breakpoints.up('md')]: {
            paddingTop: '100px',
            paddingBottom: '100px'
        }
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        // zIndex: 0
    },
    fullPageWidth: {
        marginLeft: -theme.gutters.page.xs,
        width: `calc(100% + (${theme.gutters.page.xs} * 2))`,
        maxWidth: `calc(100% + (${theme.gutters.page.xs} * 2))`,

        [theme.breakpoints.only('sm')]: {
            marginLeft: -theme.gutters.page.md,
            width: `calc(100% + (${theme.gutters.page.md} * 2))`,
            maxWidth: `calc(100% + (${theme.gutters.page.md} * 2))`
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: -theme.gutters.page.lg,
            width: `calc(100% + (${theme.gutters.page.lg} * 2))`,
            maxWidth: `calc(100% + (${theme.gutters.page.lg} * 2))`
        }
    },
    postPageWidth: {
        paddingRight: theme.gutters.page.xs,
        paddingLeft: theme.gutters.page.xs,
        [theme.breakpoints.only('sm')]: {
            paddingRight: theme.gutters.page.md,
            paddingLeft: theme.gutters.page.md
        },
        [theme.breakpoints.up('md')]: {
            padding: 0,
            margin: 'auto auto',
            width: '50%'
        },
        '& .alignfull': {
            [theme.breakpoints.up('md')]: {
                marginLeft: '-50%',
                width: 'calc(100% + 100%)',
                maxWidth: 'calc(100% + 100%)'
            }
        },
        '& .alignwide': {
            [theme.breakpoints.up('md')]: {
                marginLeft: `calc(-50% + ${theme.gutters.page.lg})`,
                width: `calc(100% + (100%) - 2*${theme.gutters.page.lg})`,
                maxWidth: `calc(100% + (100%) - 2*${theme.gutters.page.lg})`
            }
        }
    },
    content: {
        zIndex: 0,
        position: 'relative !important'
    }
}))

const LayoutContent = (props) => {
    const { className, children, pageWidth, setBackgroundImage, mainClass, hasVerticalGutters, pageContext } = props
    const classes = useStyles(props)
    const theme = useTheme()
    const pageClassName = get(pageContext, 'className')
    const [globalAuth, setGlobalAuth] = useState(null)
    const activateAuth = () => {
        setGlobalAuth(true)
    }



    useEffect(() => {
        if (globalAuth === null) {
            const authCheck = Cookie.get('authorization')
            if (!authCheck) {
                setGlobalAuth(false)
            } else {
                setGlobalAuth(true)
            }
        }
    })

    if (globalAuth === null) {
        return (
            <></>
        )
    }



    if (typeof window !== 'undefined') {
        document.body.style.setProperty('--bg-color', `${theme.palette.background.default}`)
    }

    return (
        <Box className={classnames(classes.root, className, { 'bg-default': setBackgroundImage })}>
            {globalAuth ?
                <>
                    <Header {...props} />
                    <div className={classes.content}>
                        <Box
                            className={classnames(
                                {
                                    [classes.vetricalGuttersPage]: hasVerticalGutters,
                                    [`page-${pageClassName}`]: !!pageClassName
                                },
                                props.main,
                                classes[`${pageWidth}PageWidth`],
                                mainClass
                            )}
                            component='main'
                            tabIndex={-1}
                            gutters={props.gutters || (mainClass !== 'wp-content' ? 'page' : null)}
                        >
                            <SideNav {...props} />
                            {children}
                        </Box>
                    </div>

                    <Footer {...props} />

                    <AgeGate />
                </>
            : <AuthGate setAuth={activateAuth} {...props}/>}
        </Box>
    )
}

LayoutContent.defaultProps = {
    pageWidth: 'normal',
    setBackgroundImage: false
}

LayoutContent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    pageWidth: PropTypes.oneOf(['normal', 'full', 'post']),
    setBackgroundImage: PropTypes.bool
}

export default LayoutContent

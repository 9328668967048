import React, {useState, useEffect  } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, useTheme } from '@material-ui/styles'
import SiteMobileMenu from 'tcweb-material-components/core/siteMenu/mobile'
import DesktopMenu from './components/desktopMenu'
import Icon from 'tcweb-material-components/core/icon'
import Box from 'tcweb-material-components/core/box'
import get from 'tcweb-material-components/core/poly/get'
import loadable from '@loadable/component'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Modal } from '@material-ui/core'

import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import useLogo from '../../hooks/useLogo';
import MenuItem from './components/menuItem'
import SiteMobileMenuItem from './components/siteMobileMenuItem'
import MenuImage from './menuImage'
import FullTableOfContents from '../common/fullTableOfContents'
import SkipMenuButton from 'tcweb-material-components/core/components/skipMenuButton'
import useZoomPercentage from 'tcweb-material-components/core/hooks/useZoomPercentage'
import Divider from '@material-ui/core/Divider';
import TEST_IDS from '../../constants/query-selectors'
import brandPortalKeyArtBackground from '../../assets/images/backgrounds/brand-portal/brand_portal_keyart_bg.jpg'
import Tooltip from '@material-ui/core/Tooltip';

const PushNotificationButton = loadable(() => import('../common/pushNotificationButton'))
const PUSH_NOTIFICATION_ENABLED = !!process.env.GATSBY_FEATURE_PUSH_NOTIFICATION && JSON.parse(process.env.GATSBY_FEATURE_PUSH_NOTIFICATION.toLowerCase()) === true

const useClasses = makeStyles(({ breakpoints, spacing, navigation, palette }) => ({

    logo: {
        height: '40px'
    },
    iconButton: {
        border: `2px solid transparent`,
        '&:hover, &:focus': {
            border: `2px solid ${palette.common.white}`,
            borderRadius: '0',
        },
      },
    logoLink: {
        display: 'flex',
        paddingLeft: `${spacing(1)}px`,
        width: 'initial',
        [breakpoints.up('lg')]: { marginRight: '115px' }
    },

    img: {
        marginBottom: '1em',
        borderRadius: '3%'
    },
    icon: {
        paddingBottom: '.1em',
        height: '30px'
    },
    iconTitle: { fontSize: '0.9em' },
    divider: {
        width: '3px',
        backgroundColor: palette.text.primary,
        alignSelf: 'center',
        height: `calc(${navigation.height} / 2)`,
        [breakpoints.up('sm')]:{
            height: `calc(${navigation.tablet.height} / 2)`,
        },
        [breakpoints.up('md')]:{
            height: `calc(${navigation.desktop.height} / 2)`,
        }
    },
    nav: {
        display: 'flex'
    },
    personalized: {
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center',
    },
    backdrop: { height: '100%' },
    close: {
        // position: 'absolute', // Give container full height for centering
        padding: `${spacing(2)}px`,
        [breakpoints.up('md')]:{
            padding: `${spacing(2.5)}px`,
            '&:focus': {
                border: '2px solid',
                borderRadius:'50%',
            }
        }
    },
    search: {
        display: 'flex',
        alignItems:'center'
    },
    modalHeader: {
        display: 'flex',
        flexDirection: 'row'
    },
    modalBody: {
        overflowY: 'scroll',
        height: `calc(100% - ${navigation.height})`,
        [breakpoints.up('sm')]:{
            height: `calc(100% - ${navigation.tablet.height})`,
        },
        [breakpoints.up('md')]:{
            height: `calc(100% - 3.1em)`,
        },
        background: `url(${brandPortalKeyArtBackground})`,
        backgroundSize: 'cover',
    },
    hamBurgerZoomed100:{
        paddingTop:'10vh',
        height:'100!important'
    },
    hamBurgerZoomed200:{
        paddingTop:'15vh',
        height: `calc(100% - ${navigation.height}) !important`,
    },
    hamBurgerZoomed400:{
        paddingTop:'25vh',
        height: '100%!important',
    }
}))

const getItemChildren = item => get(item, 'childItems.nodes')
export default ({ menu: { mobile } }) => {
    const { t } = useTranslation()
    const zoomPercentage = useZoomPercentage()
    const classes = useClasses()
    const logo = useLogo('brandportal')
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('xs'))
    const iconSize = isMobile ? '24px' : '30px'
    const [open, setOpen] = useState(false)
    const [isCollapsed, setIsCollapsed] = useState(false);

    const renderBrand = () => (
        <div>
        <MenuItem href='/' title={t('slug:go-to-sitename-home', { siteName: process.env.GATSBY_SITE_NAME })} aria-label={t('slug:go-to-sitename-home', { siteName: process.env.GATSBY_SITE_NAME })} classes={{ root: classes.logoLink }}>
            <img src={logo} alt={t('slug:Brand Portal Logo', { siteName: process.env.GATSBY_SITE_NAME })} className={classes.logo} />
        </MenuItem>
        </div>
    )

    const renderMenuItemImage = (item, opts = {}) =>
        <>
            {get(item, 'acf.iconImage')
                ? <MenuImage
                    className={classes.img}
                    fluidImg={get(item, 'acf.iconImage.localFile.childImageSharp.gatsbyImageData')}
                />
                : get(item, 'acf.iconBase', '').toLowerCase() !== 'none' && (
                    <div className={opts.className}>
                        <Icon
                            className={classes.icon}
                            isActive={opts.isActive}
                            iconBase={get(item, 'acf.iconBase', '').toLowerCase()}
                            iconPrefix={get(item, 'acf.iconPrefix', '').toLowerCase()}
                            iconName={get(item, 'acf.iconName', '').toLowerCase()}
                            size='2x'
                        />
                    </div>
                )
            }
            <span className={classes.iconTitle}>{t(get(item, 'label', get(item, 'title')))}</span>
        </>

    const handleClick = () => {
        setOpen(!open)
        setIsCollapsed(!isCollapsed);
    }



    const SkipMenuButtonProps = { title: t('Skip to Main Content'), role:'link',type:'button'? '' :''};

    return (
        <>
            <DesktopMenu>
                <AppBar color='secondary'>
                    <Toolbar>
                    <div>
                        <IconButton className={classes.iconButton} edge='start' title='Open Menu' aria-label='open menu'  aria-expanded={isCollapsed ? 'true' : 'false'} onClick={handleClick}
                        data-testid={TEST_IDS.headerSite.navigationButton}>
                            <MenuIcon />
                        </IconButton>
                        </div>
                        <Divider className={classes.divider} orientation='vertical' flexItem variant='middle'/>
                        <SkipMenuButton {...SkipMenuButtonProps}/>
                        <Modal
                            open={open}
                            onClose={handleClick}
                            // className={classes.backdrop}
                            BackdropProps= {{
                                style: { backgroundColor: '#070707' }
                            }}
                        >
                            <div className={classes.backdrop}>
                                <AppBar color='secondary'>
                                    <Toolbar>
                                        <IconButton className={classes.iconButton} edge='start' aria-label='Close Menu' title='Close Menu' autoFocus={open}  aria-expanded={isCollapsed ? 'true' : 'false'} onClick={handleClick} >
                                            <CloseIcon />
                                        </IconButton>
                                        <Divider className={classes.divider} orientation='vertical' flexItem variant='middle'/>
                                        {renderBrand()}
                                    </Toolbar>
                                </AppBar>

                                <Box display='block'alignItems='center' justifyContent='center' height='100%' classes={{root: classes.modalBody}} className={{ [classes.hamBurgerZoomed200]: zoomPercentage > 150, [classes.hamBurgerZoomed400]: zoomPercentage > 250,[classes.hamBurgerZoomed100]: zoomPercentage > 90}}>
                                    <FullTableOfContents />
                                </Box>
                            </div>
                        </Modal>
                        <>
                            {renderBrand()}
                        </>
                        <div className={classes.personalized}>
                            {/* <div className={classes.search}>
                                <SearchIcon />
                                <InputBase
                                    placeholder="Search…"
                                    // classes={{
                                    //     root: classes.inputRoot,
                                    //     input: classes.inputInput,
                                    // }}
                                    // inputProps={{ 'aria-label': 'search' }}
                                />
                            </div> */}
                            <Box display='flex' alignItems='center'>
                                {PUSH_NOTIFICATION_ENABLED && (
                                    <PushNotificationButton
                                        marginRight={{ xs: '10px', sm: '20px' }}
                                        FontAwesomeIconProps={{ style: { height: iconSize } }}
                                    />
                                )}
                                {/* {LOGIN_ENABLED && <ProfilePopup />} */}
                                {/* <Desktop>
                                    <Link variant='contained' fullHeight>
                                        Primary Button
                                    </Link>
                                </Desktop> */}
                            </Box>
                        </div>
                    </Toolbar>
                </AppBar>

            </DesktopMenu>

            <SiteMobileMenu
                items={mobile}
                renderMenuItemImage={renderMenuItemImage}
                getItemChildren={getItemChildren}
                MenuItemProps={{ component: SiteMobileMenuItem }}
                ButtonProps={{ component: SiteMobileMenuItem }}
                SlideDrawerProps={{
                    MenuItemProps: { component: SiteMobileMenuItem }
                }}
            />
        </>
    )
}

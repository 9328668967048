import React from 'react'
import { makeStyles} from '@material-ui/styles'
import { AppBar, Toolbar } from '@material-ui/core'

const useClasses = makeStyles(({ palette, accessibility: { highContrast } }) => ({
    root: {
        'width':'100%',
        [highContrast.mediaQuery('active')]: {
            borderBottom: `1px solid WindowText`
        }
    },
    nav: {
        'background-color': palette.background.default,
        borderBottom: `1px solid ${palette.border.primary}`
    }
}))

const DesktopMenu = ({
    children
}) => {

    const _classes = useClasses()

    return (
        <AppBar position='sticky' className={_classes.nav} >
            <Toolbar
                // disableGutters
                // gutters='navigation'
            >
                {children &&
                    <div className={_classes.root}>
                        {children}
                    </div>
                }
            </Toolbar>
        </AppBar>
    )
}

export default DesktopMenu
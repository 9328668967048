import React from 'react'
import { makeStyles } from '@material-ui/styles'

import brandPortalKeyArtBackground from '../../assets/images/backgrounds/brand-portal/brand_portal_keyart_bg.jpg'

const useClasses = makeStyles(({ palette, breakpoints }) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        background: `url(${brandPortalKeyArtBackground}) bottom`,
        backgroundSize: 'cover',
        color: palette.primary.main,
        height: '70vh',
        [breakpoints.up('lg')]: {
            minHeight: '630px',
        },
        [breakpoints.down('md')]: {
            justifyContent: 'center',
            height: 'auto'
        }
    }
}))

const SideBarHero = ({children}) => {
    const classes = useClasses()
    return (
        <div className={classes.root}>
            {children}
        </div>
  )
}

export default SideBarHero